import styled from "styled-components"

export const BasicTextModuleStyles = styled.section`
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > div {
        width: 100%;
        max-width: 700px;
      }
    }
    
    @media (min-width: 1024px) {
      gap: calc(var(--gap) * 2);
    }

    @media (min-width: 1200px) {
      gap: calc(var(--gap) * 4);
    }
  }
`

export const BasicTextStyles = styled.aside`
  max-width: 275px;
  text-align: left;

  @media (min-width: 414px) {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    flex: 1 1 50%;
  }

  @media (min-width: 1024px) {
    max-width: 600px;
  }

  h3 {
    display: inline-block;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  }

  p {
    margin-bottom: 0;
  }
  
  .gatsby-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto calc(var(--gap) / 2) auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      object-position: center;
    }
  }
`

