import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"

import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import BasicTextModule2 from "../components/BasicTextModule2/BasicTextModule2"
import BasicTextModule3 from "../components/BasicTextModule3/BasicTextModule3"

import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"

import Perks2Module from "../components/PerksModule/Perks2Module"
import Features from "../components/Features/Features"
// import LatestPosts from "../components/Post/LatestPosts"

// import GoogleMapReact from 'google-map-react';
import LeafletMap from "../components/LeafletMap/LeafletMap"
// import GoogleMap from "../components/GoogleMap/GoogleMap"

const Index = () => {

  // const googleMapsApiKey = 'AIzaSyABFGNJVL4pUt8yhcpsttLdpcy2_1w--ec'; // Replace with your actual API key
  // const address = { lat: 59.955413, lng: 30.337844 }; // Example coordinates


  return (
    <>
      <Seo title="FM7 Home - Car Window Tinting in New Zealand" />
      <Layout>
        <BannerModule
          title="Frontier of New Material FM7"
          subTitle="Lower price and Better quality for window tinting for cars, homes and commercial buildings"
        />

        <Features
          title="Featured Products from FM7."
          introduction="All FM7 films are proud of the world’s best high quality."
        />

        <BasicTextModule
          title="Our heat rejection films with Carbon and Ceramic technologies will protect your health and property from the Sun."
          content=""
          link="/products"
          linkText="View FM7 Products"
        />

        <BasicTextModule2
          title="Features and Principles of FM7 Heat Resistance Film"
          content="<p>The principles of heat resistance film using ceramic material are as follows. All materials have preferred wave-length and non-preferred wave-length under the sunlight exposure. We have applied this nature to the FM7 heat resistance films and realized the selective blocking function. The fine ceramic material applied to FM7 films is a breakthrough material developed by high nano-technology. This high functional product can provide clean and clear visibility while passing through the visible lights and blocking heat rays at the same time. Therefore, FM7 films allow the visible rays according to the purpose of usage and block out the heats and ultraviolet rays which are harmful to human body and interiors.</p>
          <p>In case of the competitive products of other companies, their functions can be effective only during a short-term period and the heat resistance function is deteriorated as time goes by because of a low-priced organic heat-resistant material. On the other hand, FM7 films can keep its high quality and function because it uses the inorganic nano-ceramic material. All materials have preferred wave-length and non-preferred wave-length under the sunlight exposure. We have applied this nature to the FM7 heat resistance films and realized the selective blocking function.The fine ceramic material applied to FM7 films is a breakthrough material developed by high nano-technology.</p>          
          <p>This high functional product can provide clean and clear visibility while passing through the visible lights and blocking heat rays at the same time. Therefore, FM7 films allow the visible rays according to the purpose of usage and block out the heats and ultraviolet rays which are harmful to human body and interiors. In case of the competitive products of other companies, their functions can be effective only during a short-term period and the heat resistance function is deteriorated as time goes by because of a low-priced organic heat-resistant material. On the other hand, FM7 films can keep its high quality and function because it uses the inorganic nano-ceramic material.</p>" 
        />

        <PerksModule>
          <Perk title="Car and house windows tinting" content="Great value for money Auto Glass window tints, Residential Property Window Tinting" />
        </PerksModule>

        <Perks2Module>
          <Perk title="car and house" content="last section" />
        </Perks2Module>

        {/* <BasicTextModule3
          title="Contact us" 
          content="Address. 8 King St. Blackburn. VIC. 3130.<br/> Phone. 03 8838 9994<br/> Email. fm7nunawading@gmail.com"
        /> */}




        <LeafletMap
          position={[-36.732571,174.7102034]}
          zoom={12}
          markerText={"Addr. 7/3 Oracle Drive, Albany, Auckland<br/> Ph. 09-426-7277 Mob. 027-777-9451<br/> Email. fm7albany@gmail.com"}
        />

        {/* <GoogleMap /> */}


        {/* <LatestPosts
          title="The Latest from Barcadia"
          introduction="Cras scelerisque, tellus sed gravida tincidunt, velit tellus blandit justo, nec viverra augue erat in erat. Maecenas iaculis sed purus non fringilla."
        /> */}
      </Layout>
    </>
  )
}

export default Index
