import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { BasicTextStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const BasicTextModule = ({ title, content, link, linkText }) => {
  return (
    <BasicTextModuleStyles className="section">
      <div className="container container__tight">
        <BasicTextStyles>
          {title && <h2>{title}</h2>}
          <p className="content-wrap" style={{ marginBottom: "60px" }} dangerouslySetInnerHTML={{ __html: content }} />
   
          <Button text={linkText} as={Link} to={link} />
        </BasicTextStyles>
        <BasicTextStyles>
          <StaticImage
            src="../../../static/skycraper.png"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </BasicTextStyles>
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
