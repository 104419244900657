import * as React from "react"
// import Button from "../Button/Button"
import { BasicTextModule2Styles } from "./BasicTextModule2Styles"
import { BasicTextStyles2 } from "./BasicTextModule2Styles"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const BasicTextModule2 = ({ title, content,link, linkText }) => {
  return (
    <BasicTextModule2Styles className="section section__padding">
      <div className="container container__tight">
        <BasicTextStyles2>
          {title && <h2>{title}</h2>}
          <div className="content-wrap" style={{ marginBottom: "60px" }} dangerouslySetInnerHTML={{ __html: content }} />

          <StaticImage
            src="../../../static/HeatResistanceFilm.jpg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </BasicTextStyles2>        
      </div>
    </BasicTextModule2Styles>
  )
}

export default BasicTextModule2
