import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
// import { MdOutlineClose as Cross } from "react-icons/md"
import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/abstract-building.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Perk
          title="Car and house windows tinting"
          content="Great value for money, Auto Glass window tints, Residential Property Window Tinting"
        >      
        </Perk>
        
        <StaticImage
            src="../../../static/before_after_2.png"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />       
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
