import React, { useState, useEffect, useRef } from "react";
import { Icon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import './leafletmap.css'

import markerIconPng from './fm7-marker-icon.png';

/*
 * 
https://stackoverflow.com/questions/65546744/not-able-to-change-center-dynamically-in-react-leaflet-v-3-x
https://codesandbox.io/s/how-to-change-center-dynamically-in-react-leaflet-v3x-d8rn7?file=/src/App.js:837-866

https://github.com/andrewl/gatsby-geo-simple-map/blob/master/src/pages/index.js
React Leaflet tutorial
https://blog.logrocket.com/react-leaflet-tutorial/
Add All Store Locations to a React Leaflet Map with Location Data From GraphCMS
https://egghead.io/lessons/graphcms-add-all-store-locations-to-a-react-leaflet-map-with-location-data-from-graphcms

 */

const defaultLocations =   
  { name: "FM7 Auckland Albany", position: [-36.7322504, 174.7127912], popdesc: "<p>Addr. 7/3 Oracle Drive, Albany, Auckland<br/> Ph. 09-426-7277 Mob. 027-777-9451<br/> Email. fm7albany@gmail.com<br/><a href='https://www.facebook.com/Fm7albany/' target='_blank'>Facebook</a></p>" }
;

const locations = [
  { name: "FM7 Auckland Albany", position: [-36.7322504, 174.7127912], popdesc: "<p>Addr. 7/3 Oracle Drive, Albany, Auckland<br/> Ph. 09-426-7277 Mob. 027-777-9451<br/> Email. fm7albany@gmail.com<br/><a href='https://www.facebook.com/Fm7albany/' target='_blank'>Facebook</a></p>" },
  { name: "FM7 Auckland South", position: [-36.9477556, 174.8895546], popdesc: "<p>Addr. 2E Kerwyn Avenue, East Tamaki. Auckland<br/> Ph. 09-265-1414 Mob. 021-060-4052<br/> Email. fm7southauckland@gmail.com<br/><a href='https://www.facebook.com/profile.php?id=100038463386908' target='_blank'>Facebook</a></p>" },
  { name: "FM7 Auckland West", position: [-36.853797, 174.633975], popdesc: "<p>Addr. 15E Collard Place, Henderson, Auckland.<br/>Ph. 09-218-8866 Mob. 021-996-924<br/>Email. fm7henderson@gmail.com<br/><a href='https://www.facebook.com/profile.php?id=100089298500413' target='_blank'>Facebook</a></p>" },
  { name: "FM7 Tauranga", position: [-37.663584, 176.1978959], popdesc: "<p>Addr. 6/25 Maru st, Mount Maunganui, Tauranga, Bay of plenty.<br/> Ph. 07-281-2434 Mob. 021-144-1169<br/>Email. Fm7tauranga@gmail.com<br/><a href='https://www.facebook.com/FM7WindowTintingTauranga/' target='_blank'>Facebook</a></p>"},  
  { name: "FM7 Christchurch", position: [-43.543233, 172.639454], popdesc: "<p>Addr. 5A Kingsley Street, Sydenhan, Christchurch. 8023<br/>Ph. 03-377-7746 Mob. 021-0824-4241<br/>Email. Fm7chch@gmail.com<br/><a href='https://www.facebook.com/fm7tintingchristchurch/' target='_blank'>Facebook</a></p>" },
];

function SetViewOnClick({ coords }) {
  const map = useMap();
  map.setView(coords, map.getZoom());

  return null;
}

function LeafletMap() {
  const [activeLocation, setActiveLocation] = useState();
  const markerRef = useRef();

  const defaultCenter = [-36.8536848, 174.6339817];
  const zoom = 9;

  const [coords, setCoords] = useState(defaultCenter);

  useEffect(() => {
    setActiveLocation(defaultLocations);
    markerRef.current?.openPopup();
  }, [])

  const handleLocationClick = (location) => {
    setActiveLocation(location);
    setCoords(location.position);
  };

  if (typeof window !== 'undefined') {
    const markerIcon = new Icon({
        iconUrl: markerIconPng
      });

    return (
    <section>      
    <div className="container container__tight leafletmap">
      <div className="single">
        <div style={{ margin: "0 auto", width: "69%" }}>
          <strong>Please select one of following branches</strong>
          <h3 style={{ marginBottom: "10px" }} >New Zealand</h3>
          {activeLocation && (
            <div>
              <h2>{activeLocation.name}</h2>
              <div dangerouslySetInnerHTML={{ __html: activeLocation.popdesc }} />
            </div>
          )}
        </div>

        <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
          {locations.map((location) => (
            <li key={location.name} onClick={() => handleLocationClick(location)}>
              <button>{location.name}</button>
            </li>
          ))}
        </ul>
      </div>
      <div className="double">
        <MapContainer center={defaultCenter} zoom={zoom} >
          <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'/>
            {locations.map((location) => (
              <Marker key={location.name} position={location.position} icon={markerIcon}>
                <Popup>
                  <h4>{location.name}</h4>
                  <div dangerouslySetInnerHTML={{ __html: location.popdesc }} />
                </Popup>
              </Marker>
            ))}

          {/* If you want display one per click 
          {activeLocation && (
             <Marker key={activeLocation.name} position={activeLocation.position} icon={
              new Icon({
                iconUrl: markerIconPng,
              })
            }>
              <Popup open={true}>
                <h4>{activeLocation.name} {activeLocation.position}</h4>
                <div dangerouslySetInnerHTML={{ __html: activeLocation.popdesc }} />
              </Popup>
           </Marker>
           )} */}

           <SetViewOnClick coords={coords} /> 
        </MapContainer>         
      </div>      
    </div>
    </section>
    );
  }else{
    return (
        <section>
            Nothing to return 
        </section>
    );
  }
}

export default LeafletMap;
