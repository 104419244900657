import styled from "styled-components"

export const BasicTextModule2Styles = styled.section`
  position: relative;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > div {
        width: 100%;
        max-width: 700px;
      }
    }
    
  }
`

export const BasicTextStyles2 = styled.section`

  h3 {
    display: inline-block;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  }

  p {
    margin-bottom: 0;
  }
  
  .gatsby-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto calc(var(--gap) / 2) auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      object-position: center;
    }
  }
`

