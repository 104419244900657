import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles2 } from "./PerksModuleStyles"
// import { MdOutlineClose as Cross } from "react-icons/md"
// import Perk from "./Perk"

const Perks2Module = () => {
  return (
    <PerksModuleStyles2 className="section section__padding">    
      <div className="container container__tight">               
          <div>
            <h3>Car and House Window Tinting Across New Zealand</h3>
            <p>FM7 offers exclusive eco-friendly heat rejection Carbon and Ceramic films for car window tinting in Auckland and world-class house window tinting across New Zealand. With state-of-the-art technology and the best product processes, we manufacture and sell high-quality coating films to both domestic and international markets.</p>
            <p>Our world-class tinting films are the best fit for both building and car interiors, ensuring they are energy-efficient and eco-friendly. We prioritize original technology and select only the finest materials to maintain our status as a super brand in New Zealand. Car tints in New Zealand can keep your vehicle up to 60% cooler, even when parked under the scorching sun. Quality car tint blocks up to 99% of UV rays, reducing the risk of skin cancer, natural skin damage, and accelerated aging.</p>
            <p>Our tints prevent sun glare, snow glare, and even headlight glare at night. Window tinting in Auckland and across New Zealand ensures high visibility through your windows. The hyper-transparent PET material and high-tech nano-ceramic material provide clear and clean sight without causing eye strain. Our house window tinting solutions offer significant benefits, including enhancing the security and privacy of your home, ensuring that no one can look inside depending on the shade of tint.</p>
            <p>There is no interruption to running electronic equipment such as mobile phones and car navigation systems in your car or building. Our window tinting solutions across New Zealand reduce glares and UV rays while enhancing the aesthetics of your property. The ceramic-based tinting films we use don’t rely on low-cost materials, ensuring long-term functionality for your building. They protect your furniture and interiors from fading, safeguard your family's skin from harmful sunlight, and can reduce your energy costs by up to 35%.</p>
          </div>

          <StaticImage
            src="../../../static/windows.png"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
      </div>
    </PerksModuleStyles2>
  )
}

export default Perks2Module
